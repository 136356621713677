import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { RESET_PASSWORD_MUTATION } from '../../queries/resetPasswordMutation';
import { useLocation } from '@reach/router';

export type UseChangePasswordProps = {
  password: String;
  passwordConfirmation: String;
  code: String;
};

export const useChangePassword = () => {
  const [resetPassword, { loading, error }] = useMutation(
    RESET_PASSWORD_MUTATION
  );
  const { search } = useLocation();
  const codeValue = search.replace('?code=', '');

  const handleChangePassword = useCallback(
    ({ password, passwordConfirmation }: UseChangePasswordProps) =>
      resetPassword({
        variables: {
          password: `${password}`,
          passwordConfirmation: `${passwordConfirmation}`,
          code: `${codeValue}`,
        },
        onCompleted: ({ resetPassword }) => {
          return resetPassword;
        },
        onError: error => {
          console.log('Error', error);
        },
      }),
    []
  );

  return { handleChangePassword, loading, error };
};
