import gql from 'graphql-tag';

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword(
    $password: String!
    $passwordConfirmation: String!
    $code: String!
  ) {
    resetPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      code: $code
    ) {
      jwt
    }
  }
`;
