import React from 'react';
import { Typography, Layout, Row, Col } from 'antd';
import { globalStyles } from '../styles/variables';
import useMediaQuery from '../utils/hooks/useMediaQuery';
import PasswordChangeForm from '../components/PasswordChangeForm/PasswordChangeForm';
import { BackgroundImage } from '../components/BackgroundImage/BackgroundImage';
import './new-password.less';
import { LogoInHeader } from '../components/LogoInHeader/LogoInHeader';

const NewPassword = () => {
  const { Title } = Typography;

  const { isDesktop, isTablet, matches } = useMediaQuery(
    globalStyles.minScreenWidth.lg
  );

  return (
    <Layout>
      <LogoInHeader />
      <Row align={isTablet ? 'middle' : 'top'} className="page-container">
        <Col
          span={matches ? 10 : 20}
          offset={isDesktop ? 4 : 2}
          style={{ zIndex: 1 }}
        >
          <Row className="new-password-page-form-wrapper">
            <Col span={24}>
              <Title level={3} className="new-password-page-heading">
                Nowe hasło
              </Title>
              <PasswordChangeForm />
            </Col>
          </Row>
        </Col>
      </Row>
      <BackgroundImage />
    </Layout>
  );
};

export default NewPassword;
