import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { Button, Form, Input, Typography } from 'antd';
import {
  useChangePassword,
  UseChangePasswordProps,
} from '../../utils/hooks/useChangePassword';
import { ROUTES } from './../../constants/routes';
import { GlobalStateContext } from '../../context/GlobalContextProvider';

const PasswordChangeForm: React.FC = () => {
  const { Paragraph } = Typography;
  const {
    state: { authenticated },
  } = useContext(GlobalStateContext);

  const { handleChangePassword, loading, error } = useChangePassword();

  const onFinish = async (input: UseChangePasswordProps) => {
    const { data } = await handleChangePassword(input);
    if (data) {
      navigate(authenticated ? ROUTES.HOME : ROUTES.SIGN_IN);
    }
  };

  const onFinishFailed = (errorInfo: unknown) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Wpisz swoje nowe hasło!',
          },
        ]}
        validateStatus={error ? 'error' : loading ? 'validating' : 'success'}
        hasFeedback={loading || error ? true : false}
      >
        <Input.Password className="formInput" placeholder={'Hasło'} />
      </Form.Item>

      <Form.Item
        name="passwordConfirmation"
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: 'Potwierdź nowe hasło!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('Wpisane hasła do siebie nie pasują!')
              );
            },
          }),
        ]}
      >
        <Input.Password className="formInput" placeholder={'Powtórz hasło'} />
      </Form.Item>

      {error && (
        <Paragraph type="danger" className="errot-text">
          Wystąpił błąd, spróbuj ponownie.
        </Paragraph>
      )}
      <Form.Item wrapperCol={{ span: 16 }}>
        <Button type="primary" className="btn-mobile" htmlType="submit">
          Zmień hasło
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PasswordChangeForm;
